import React from 'react'
import Navbar from '../../layouts/site-layout/components/Navbar'
import NewFooter from '../../layouts/site-layout/components/NewFooter'

const index = () => {

    return (
        <>
            <Navbar />
            <div style={{ position: "fixed", top: "64px", left: "0", width: "100%", height: "calc(100% - 64px)", border: "1px solid #ccc" }}>
                <iframe src="https://student-dashboard-2e72a2.netlify.app/" width="100%" height="100%" frameBorder="0" />
            </div>
        </>

    )
}

export default index